import React from 'react';
import Slide from 'react-reveal/Slide';

import SEO from '../components/seo';

import { Menu, Item } from '../styles/styles.js';

const Bebidas = () => (
  <>
    <SEO title="Bebidas" />

    <Menu className="menu">
      <Slide left cascade duration={300}>
        <h1 className="menu-title">Bebidas</h1>
      </Slide>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">Coca-Cola 2L</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">15,00</h4>
          </div>
        </div>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">Xereta 2L</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">8,00</h4>
          </div>
        </div>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">Água sem gás</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">3,00</h4>
          </div>
        </div>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">Água com gás</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">4,00</h4>
          </div>
        </div>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">Suco sabores Del valle</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">6,00</h4>
          </div>
        </div>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">Refrigerante Lata</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">6,00</h4>
          </div>
        </div>

        <p className="item-desc">Consulte sabores</p>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">Cervejas Long Neck</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">9,00</h4>
          </div>
        </div>

        <p className="item-desc">Budweiser, Heineken e Corona</p>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">Cerveja Skol/Antarctica 600ml</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">12,00</h4>
          </div>
        </div>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">Cerveja Original/Heineken 600ml</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">15,00</h4>
          </div>
        </div>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">Taça de Vinho</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">10,00</h4>
          </div>
        </div>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">Caipirinha</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">12,00</h4>
          </div>
        </div>
      </Item>
    </Menu>
  </>
);

export default Bebidas;
